import React, { useContext, useEffect, useMemo, useRef } from 'react'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import StickyPromptCard from 'components/Common/StickyPromptCard/StickyPromptCard'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { CarHireReengagementView } from 'components/Common/StickyPromptCard/useCarHireReengagementSelectionLogic'
import { setCarHireReengagementDismissedInStorage } from 'storage/carHireReengagement'
import qs from 'qs'
import { pluralizeToString } from 'lib/string/pluralize'
import { useInView } from 'react-intersection-observer'
import { getListName, productImpression, productClick } from 'analytics/enhanced-ecommerce'
import GeoContext from 'contexts/geoContext'
import uuidV4 from 'lib/string/uuidV4Utils'
import { GAImpressionFieldObject } from 'analytics/eventDefinitions'
import StickyPromptCardContent from 'components/Common/StickyPromptCard/StickyPromptCardContent'
import StickyPromptCardFooter from 'components/Common/StickyPromptCard/StickyPromptCardFooter'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import TextButton from 'components/Luxkit/Button/TextButton'

const EVENT_CATEGORY = 'car-hire-reengagement-card'

interface Props {
  onClose?: () => void
  show: boolean
  stickyTop?: number
  carHireReengagementView: CarHireReengagementView
  offerSummary: App.CarHireOfferSummary
}

function CarHireReengagementCard(props: Props) {
  const {
    carHireReengagementView,
    offerSummary,
    show,
    stickyTop,
    onClose = noop,
  } = props
  const [inViewRef, inView] = useInView({ triggerOnce: true })

  const listId = useRef<string>()
  const listName = getListName(
    'CarHire',
    'global',
    'Car Hire Re-engagement Card',
  )
  const { currentCurrency } = useContext(GeoContext)

  // This isn't a real ID, but it's a unique enough indication of what the card is showing
  const analyticsId = `carhire-${carHireReengagementView.filters.pickUpLocationId}`
  const analyticsName = `Car hire in ${carHireReengagementView.placeName}`

  useEffect(() => {
    if (show && inView) {
      listId.current = uuidV4()
      const impression: GAImpressionFieldObject = {
        id: analyticsId,
        name: analyticsName,
        list: listName,
        category: EVENT_CATEGORY,
        quantity: 1,
      }
      productImpression(currentCurrency, [impression], {
        listId: listId.current,
        listName,
      })
    }
  }, [currentCurrency, listName, show, inView, analyticsId, analyticsName])

  const onClick = () => {
    if (listId.current) {
      productClick(
        currentCurrency,
        {
          id: analyticsId,
          name: analyticsName,
          category: EVENT_CATEGORY,
          quantity: 1,
        },
        {
          listId: listId.current,
          listName,
        },
      )
    }
    setCarHireReengagementDismissedInStorage('clicked')
    onClose()
  }

  const onDismiss = () => {
    setCarHireReengagementDismissedInStorage('closed')
    onClose()
  }

  const vehicleImage = useMemo(() => ({
    ...offerSummary.vehicle.image,
    // modify the image URL to utilise car trawlers image options for a better image
    // 'secondary' faces left, 'primary' faces right
    url: `${offerSummary.vehicle.image.url?.replace('primary', 'secondary')}?w=68&dpr=2`,
  }), [offerSummary])

  const queryParams = {
    pickUpName: carHireReengagementView.placeName,
    dropOffName: carHireReengagementView.placeName,
    pickUpId: carHireReengagementView.filters.pickUpLocationId,
    dropOffId: carHireReengagementView.filters.dropOffLocationId,
    pickUpDate: carHireReengagementView.filters.pickUpDate,
    pickUpTime: carHireReengagementView.filters.pickUpTime,
    dropOffDate: carHireReengagementView.filters.dropOffDate,
    dropOffTime: carHireReengagementView.filters.dropOffTime,
    ageCategory: carHireReengagementView.filters.driversAgeCategory,
    age: carHireReengagementView.filters.driversAge,
    pickUpSearchType: carHireReengagementView.filters.pickUpSearchType,
    dropOffSearchType: carHireReengagementView.filters.dropOffSearchType,
    isPickUpAirport: carHireReengagementView.filters.isPickUpAtAirport,
  }

  return (
    <StickyPromptCard
      title={`Need to hire a car in ${carHireReengagementView.placeName}?`}
      onClose={onDismiss}
      to={`/search/car-hire?${qs.stringify(queryParams)}`}
      onClick={onClick}
      show={show}
      stickyTop={stickyTop}
      ref={inViewRef}
    >
      <StickyPromptCardContent image={vehicleImage}>
        <VerticalSpacer gap={4}>
          <BodyText weight="bold" variant="medium" lineClamp={2}>
            We’ve found {pluralizeToString('car', carHireReengagementView.offerCount)}
          </BodyText>
          <CaptionTextBlock variant="medium">
            {carHireReengagementView.pickUpLocationName} - {carHireReengagementView.dropOffLocationName}
          </CaptionTextBlock>
        </VerticalSpacer>
      </StickyPromptCardContent>
      <StickyPromptCardFooter
        callToAction={<TextButton
          variant="default"
          kind="primary"
          size="small"
          nonInteractable
        >
          View Cars
        </TextButton>}
      >
        <PriceRowPriceCaption>{offerSummary.duration} days from</PriceRowPriceCaption>
        <PriceRowPrice
          size="S"
          price={offerSummary.totalCharge.price}
          saleUnit="total"
        />
      </StickyPromptCardFooter>
    </StickyPromptCard>
  )
}

export default CarHireReengagementCard
