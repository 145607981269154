import IconButton from 'components/Luxkit/Button/IconButton'
import LineMultiplyIcon from 'components/Luxkit/Icons/line/LineMultiplyIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { MouseEventHandler, PropsWithChildren, forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import StickyPrompt from './StickyPrompt'
import noop from 'lib/function/noop'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import Clickable from '../Clickable'

const CardRoot = styled(StickyPrompt)`
  padding: ${rem(20)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  transition: border-color 200ms ease;
  width: 100vw;

  ${mediaHoverable} {
    &:hover {
      border-color: ${props => props.theme.palette.neutral.default.one};
    }
  }

  ${mediaQueryUp.tablet} {
    width: ${rem(360)};
    padding: ${rem(16)};
  }
`

const CloseButton = styled(IconButton)`
  transform: translate(${rem(4)}, ${rem(-4)});
`

interface Props {
  title: string
  show?: boolean;
  to?: string;
  onClose?: () => void;
  onClick?: () => void;
  stickyTop?: number;
}

const StickyPromptCard = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    title,
    show,
    children,
    onClose = noop,
    onClick,
    to,
    stickyTop,
  } = props

  const handleClose = useCallback<MouseEventHandler>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onClose()
  }, [onClose])

  return (<CardRoot show={show} stickyTop={stickyTop}>
    <Clickable onClick={onClick} to={to} ref={ref}>
      <Group direction="horizontal" gap={8} horizontalAlign="space-between">
        <Heading variant="heading6" wrap="truncate">{title}</Heading>
        <CloseButton
          kind="tertiary"
          onClick={handleClose}
          size="small"
          shape="square"
        >
          <LineMultiplyIcon />
        </CloseButton>
      </Group>
      {children}
    </Clickable>
  </CardRoot>
  )
})

export default StickyPromptCard
